<template>
  <div v-if="address">
    <div class="box block">
      <div v-if="isEditing">
        <address-editor @closeAddressEditor="closeEditor"></address-editor>
      </div>
      <div v-else>
        <h1 class="title is-4">
          <b-icon icon="home"></b-icon>
          Adres
        </h1>
        <hr />
        <div class="level">
          <div class="level-left">
            <div class="level-item">Twój aktualny adres to:</div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-button
                type="is-primary"
                icon-left="pencil"
                size="is-small"
                @click="showEditor()"
                >Edytuj</b-button
              >
            </div>
          </div>
        </div>
        <div>
          {{ addressToString(address) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddressEditor from "@/components/editors/AddressEditor";

export default {
  components: {
    "address-editor": AddressEditor,
  },

  data() {
    return {
      isEditing: false,
    };
  },

  methods: {
    showEditor() {
      this.isEditing = true;
    },

    closeEditor() {
      this.isEditing = false;
    },

    addressToString(address) {
      const { city, postcode, street, local_nb } = address;
      return `${postcode} ${city}, ul. ${street} ${local_nb}`;
    },
  },

  computed: {
    address() {
      return this.$store.getters["clientProfile/address"];
    },
  },
};
</script>