<template>
  <div>
    <h1 class="title is-4">
      <b-icon icon="mail"></b-icon>
      Email
    </h1>
    <hr />
    <div>Twój adres email to:</div>
    <div class="columns is-vcentered">
      <div class="column is-4">
        <b-field>
          <b-input v-model="email" type="email" size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-button
          type="is-success"
          icon-left="check"
          size="is-small"
          outlined
          class="mr-3"
          @click="saveAndClose"
          >Zapisz</b-button
        >
        <b-button
          type="is-danger"
          icon-left="close"
          size="is-small"
          outlined
          @click="closeEditor"
          >Anuluj</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        email: "",
      },
    };
  },

  methods: {
    saveAndClose() {
      const payload = {
        user: {
          email: this.form.email,
        },

        address: {},

        details: {},
      };

      this.$store
        .dispatch("clientProfile/updateEmail", payload)
        .then((response) => {
          if (response.status !== 201) {
            this.editFailedNotification();
          } else {
            this.editSuccessNotification();
          }
        })
        .catch((err) => {
          this.editFailedNotification(err.message);
        })
        .finally(() => {
          this.closeEditor();
        });
    },

    closeEditor() {
      this.$emit("closeEmailEditor");
    },

    editSuccessNotification() {
      this.$buefy.notification.open({
        message: "Pomyślnie zmieniono adres email",
        type: "is-primary",
        position: "is-bottom",
        duration: 10000,
      });
    },

    editFailedNotification(msg = "Nie udało się zmienić adresu email") {
      this.$buefy.notification.open({
        message: msg,
        type: "is-danger",
        position: "is-bottom",
        duration: 10000,
      });
    },
  },

  computed: {
    email: {
      get() {
        return this.$store.getters["clientProfile/email"];
      },

      set(value) {
        this.form.email = value;
      },
    },
  },
};
</script>