import { render, staticRenderFns } from "./PhoneSettings.vue?vue&type=template&id=5ae6d909&"
import script from "./PhoneSettings.vue?vue&type=script&lang=js&"
export * from "./PhoneSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports