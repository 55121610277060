<template>
  <div class="box" v-if="email">
    <div v-if="isEditing">
      <email-editor @closeEmailEditor="closeEditor"></email-editor>
    </div>
    <div v-else>
      <h1 class="title is-4">
        <b-icon icon="mail"></b-icon>
        Email
      </h1>
      <hr />
      <div class="level">
        <div class="level-left">
          <div class="level-item">Twój adres email to:</div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-button
              type="is-primary"
              icon-left="pencil"
              size="is-small"
              @click="showEditor()"
              >Edytuj</b-button
            >
          </div>
        </div>
      </div>
      <div>{{ email }}</div>
    </div>
    <!-- <div class="block mt-3">
      <b-field>
        <b-switch v-model="emailNotification">Powiadomienia email</b-switch>
      </b-field>
    </div> -->
  </div>
</template>
<script>
import EmailEditor from "@/components/editors/EmailEditor";

export default {
  components: {
    "email-editor": EmailEditor,
  },

  data() {
    return {
      isEditing: false,
    };
  },

  methods: {
    showEditor() {
      this.isEditing = true;
    },

    closeEditor() {
      this.isEditing = false;
    },
  },

  computed: {
    email() {
      return this.$store.getters["clientProfile/email"];
    },
    /* emailNotification: {
      get() {
        return this.$store.getters["clientProfile/notificationSettings"].email;
      },

      set(value) {
        this.$store.dispatch("clientProfile/updateEmailNotification", value);
      },
    }, */
  },
};
</script>