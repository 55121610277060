<template>
  <div>
    <div class="box" v-if="phone">
      <div v-if="isEditing">
        <phone-editor @closePhoneEditor="closeEditor"></phone-editor>
      </div>
      <div v-else>
        <h1 class="title is-4">
          <b-icon icon="phone"></b-icon>
          Telefon
        </h1>
        <hr />
        <div class="level">
          <div class="level-left">
            <div class="level-item">Twój nr telefonu to:</div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-button
                type="is-primary"
                icon-left="pencil"
                size="is-small"
                @click="showEditor()"
                >Edytuj</b-button
              >
            </div>
          </div>
        </div>
        <div>{{ phone }}</div>
      </div>
      <!-- <div class="block mt-3">
        <b-field>
          <b-switch v-model="SMSNotification">Powiadomienia SMS</b-switch>
        </b-field>
      </div> -->
    </div>
  </div>
</template>
<script>
import PhoneEditor from "@/components/editors/PhoneEditor";

export default {
  components: {
    "phone-editor": PhoneEditor,
  },

  data() {
    return {
      isEditing: false,
    };
  },

  methods: {
    showEditor() {
      this.isEditing = true;
    },

    closeEditor() {
      this.isEditing = false;
    },
  },

  computed: {
    phone() {
      return this.$store.getters["clientProfile/phone"];
    },
    /* SMSNotification: {
      get() {
        return this.$store.getters["clientProfile/notificationSettings"].SMS;
      },

      set(value) {
        this.$store.dispatch("clientProfile/updateSMSNotification", value);
      },
    }, */
  },
};
</script>