<template>
  <section>
    <client-nav></client-nav>
    <section class="section container">
      <div class="columns">
        <user-nav></user-nav>
      <div class="column">
        <div class="block">
          <div class="columns is-multiline">
            <div
              class="column block is-half is-offset-one-quarter is-radiusless"
            >
              <h1 class="title is-4">
                <b-icon icon="account"></b-icon>
                {{ name }}
              </h1>
              <hr />
            </div>

            <!-- Adres box -->
            <div class="column is-half is-offset-one-quarter">
              <address-settings></address-settings>
            </div>

            <!-- Email box -->
            <div class="column is-half is-offset-one-quarter">
              <email-settings></email-settings>
            </div>

            <!-- Telefon box -->
            <div class="column is-half is-offset-one-quarter">
              <phone-settings></phone-settings>
            </div>
            <!-- Zmiana hasła box -->
            <div class="column is-half is-offset-one-quarter">
              <password-editor></password-editor>
            </div>
            <!-- Wylaczenie konta -->
            <div class="column is-half is-offset-one-quarter">
              <turn-off-account></turn-off-account>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
  </section>
</template>

<script>
import ClientNav from "@/components/ClientNav";
import UserNav from '../../components/common/UserNav.vue';
import AddressSettings from "@/components/settings/AddressSettings";
import EmailSettings from "@/components/settings/EmailSettings";
import PhoneSettings from "@/components/settings/PhoneSettings";
import PasswordEditor from "@/components/editors/PasswordEditor";
import TurnOffAccount from '../../components/settings/TurnOffAccount.vue';

export default {
  components: {
    "client-nav": ClientNav,
    "user-nav": UserNav,
    "address-settings": AddressSettings,
    "email-settings": EmailSettings,
    "phone-settings": PhoneSettings,
    "password-editor": PasswordEditor,
    "turn-off-account": TurnOffAccount
  },

  created() {
       const userId = this.$store.getters["auth/id"];
    this.$store.dispatch("auth/loadUserData", userId);
  },

  computed: {
    name() {
      return this.$store.getters["clientProfile/name"];
    },
  },
};
</script>