<template>
  <div>
    <div class="box">
            <h1 class="title is-4">
      <b-icon icon="account"></b-icon>
      Zamknięcie konta
    </h1>
    <hr />
    <b-button
      @click="turnOffAccount"
      type="is-primary"
      icon-left="check"
      size="is-small"
      outlined
      >Zamknij konto w serwisie</b-button
    >
    </div>
  </div>
</template>
<script>


export default {
  components: {
    
  },

  data() {
    return {
     
    };
  },

  methods: {
      turnOffAccount(){
          const decision = confirm("Czy na pewno chcesz zamknąć konto w serwisie AppCars?")
          if(decision){
              this.$store.dispatch("auth/closeAccount", {
                  token: this.token,
                  uid: this.uid,
                  type: 'user',
              }).then(res => {
                  if(res) this.logout()
              })
              
          }
      },
      logout() {
        this.$store.dispatch("auth/logout");
        localStorage.clear();
        this.$router.push({ name: "Home" });
      },

  },

  computed: {
    token(){
    return this.$store.getters["auth/authToken"];
    },
    uid(){
      return this.$store.getters["auth/id"];
    },
    phone() {
      return this.$store.getters["clientProfile/phone"];
    },
    /* SMSNotification: {
      get() {
        return this.$store.getters["clientProfile/notificationSettings"].SMS;
      },

      set(value) {
        this.$store.dispatch("clientProfile/updateSMSNotification", value);
      },
    }, */
  },
};
</script>