<template>
  <div>
    <h1 class="title is-4">
      <b-icon icon="phone"></b-icon>
      Telefon
    </h1>
    <hr />
    <div>Twój aktualny nr telefonu to:</div>
    <div class="columns is-vcentered">
      <div class="column is-4">
        <b-field>
          <b-input v-model="phone" size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-button
          type="is-success"
          icon-left="check"
          size="is-small"
          outlined
          class="mr-3"
          @click="saveAndClose"
          >Zapisz</b-button
        >
        <b-button
          type="is-danger"
          icon-left="close"
          size="is-small"
          outlined
          @click="closeEditor"
          >Anuluj</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        phone: "000000000",
      },
    };
  },

  methods: {
    saveAndClose() {
      const payload = {
        user: {},

        address: {},

        details: {
          phone: this.form.phone,
        },
      };

      this.$store
        .dispatch("clientProfile/updatePhoneNumber", payload)
        .then((response) => {
          if (response.status !== 201) {
            this.editFailedNotification();
          } else {
            this.editSuccessNotification();
          }
        })
        .catch((err) => {
          this.editFailedNotification(err.message);
        })
        .finally(() => {
          this.closeEditor();
        });
    },

    closeEditor() {
      this.$emit("closePhoneEditor");
    },

    editSuccessNotification() {
      this.$buefy.notification.open({
        message: "Pomyślnie zmieniono nr telefonu",
        type: "is-primary",
        position: "is-bottom",
        duration: 10000,
      });
    },

    editFailedNotification(msg = "Nie udało się zmienić nr telefonu") {
      this.$buefy.notification.open({
        message: msg,
        type: "is-danger",
        position: "is-bottom",
        duration: 10000,
      });
    },
  },

  computed: {
    phone: {
      get() {
        return this.$store.getters["clientProfile/phone"];
      },

      set(value) {
        this.form.phone = value;
      },
    },
  },
};
</script>